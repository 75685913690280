import type { GetStaticPaths } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import type { CMSComponentMap } from '@cbhq/cdx-components'
import { logError, logInfo } from '@cbhq/cdx-components'
import {
  LISTING_TEMPLATE_CONTENT_MODEL_ID,
  EDITORIAL_TEMPLATE_CONTENT_MODEL_ID,
  getSearchPath,
  getContentEntry,
  getEntryTypeId,
  filterSubNavLinkFields,
  fetchSlugs,
  LANDING_TEMPLATE_CONTENT_MODEL_ID,
} from '@cbhq/cdx-contentful'
import type {
  ComposePageEntry,
  PageTemplateExtraProps,
  PageTemplateFields,
} from '@cbhq/cdx-contentful'

import type { ContentfulAppProps } from './App'
import { componentMap as defaultComponentMap } from './componentMap'
import { FallbackLoader } from './FallbackLoader'
import { getAppConfig } from '../config'
import { createGetStaticProps } from '../createGetStaticProps'
import { handleStaticPropsEditorial } from '../templates/Editorial'
import { handleStaticPropsLanding } from '../templates/Landing'
import { handleStaticPropsListing } from '../templates/Listing'
import type { StaticQueryOpts, DataHandlerReturn } from '../types'
import { addSlugBasePath } from '../utils/addSlugBasePath'
import { getOptionalCatchAllRoutePaths } from '../utils/getOptionalCatchAllRoutePaths'

const defaultTemplateMap: CMSComponentMap = {
  [EDITORIAL_TEMPLATE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.EditorialTemplate),
  ),
  [LANDING_TEMPLATE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.LandingTemplate),
  ),
  [LISTING_TEMPLATE_CONTENT_MODEL_ID]: dynamic(() =>
    import('../templates/Listing').then((x) => x.ListingTemplate),
  ),
}

export type ApplicationSettingsExtraProps = {
  customLogoHeight?: number
  showCDXHeader?: boolean
}

export type ContentPageProps = ContentfulAppProps<
  PageTemplateFields,
  PageTemplateExtraProps
> & {
  templateMap?: CMSComponentMap
  componentMap?: CMSComponentMap
  applicationSettingsExtraProps?: ApplicationSettingsExtraProps
  isEmbeddedView?: boolean
}

export const ContentPage = ({
  page,
  extraProps,
  templateMap: customTemplateMap,
  componentMap: customComponentMap,
  applicationSettingsExtraProps,
  isEmbeddedView,
}: ContentPageProps) => {
  const { asPath, push } = useRouter()
  const router = useRouter()

  if (router.isFallback) {
    // Render the loading state for on-demand Blog pages during the build process.
    return <FallbackLoader />
  }

  const onSearch = (nextTerm: string) => {
    push(
      `${getSearchPath(page.content.fields.projectSettings)}?term=${nextTerm}`,
      undefined,
    )
  }

  const templateMap = {
    ...defaultTemplateMap,
    ...customTemplateMap,
  }

  const templateType = getEntryTypeId(page.content)
  const Template = templateMap[templateType]

  if (!Template) return null

  return (
    <Template
      {...page?.content.fields}
      {...extraProps}
      onSearch={onSearch}
      path={asPath}
      applicationSettingsExtraProps={applicationSettingsExtraProps}
      componentMap={{
        ...defaultComponentMap,
        ...customComponentMap,
      }}
      isEmbeddedView={isEmbeddedView}
    />
  )
}

type StaticPropsTemplateMap = {
  [modelId: string]: (
    composePage: ComposePageEntry<any>,
    queryOpts: StaticQueryOpts,
  ) => DataHandlerReturn<any, any>
}

const defaultStaticPropsTemplateMap: StaticPropsTemplateMap = {
  [EDITORIAL_TEMPLATE_CONTENT_MODEL_ID]: handleStaticPropsEditorial,
  [LANDING_TEMPLATE_CONTENT_MODEL_ID]: handleStaticPropsLanding,
  [LISTING_TEMPLATE_CONTENT_MODEL_ID]: handleStaticPropsListing,
}

export const getStaticPropsContentPage = ({
  templateMap: customTemplateMap = {},
}: {
  templateMap?: StaticPropsTemplateMap
} = {}) =>
  createGetStaticProps<PageTemplateFields, PageTemplateExtraProps>(
    async ({ queryOpts }) => {
      const {
        contentfulConfig: { appTagId },
      } = getAppConfig()

      queryOpts.slug = addSlugBasePath(queryOpts.slug)

      let composePage

      const slugPattern = /^[a-zA-Z0-9/_-]+$/

      if (!slugPattern.test(queryOpts.slug)) {
        return null
      }

      try {
        composePage = (await getContentEntry(
          queryOpts,
          true,
        )) as ComposePageEntry<PageTemplateFields>
      } catch (error) {
        // 429 results from Contentful Rate Limit (https://www.contentful.com/developers/docs/references/content-management-api/#/introduction/api-rate-limits)
        if (
          (error instanceof Error &&
            error?.toString()?.indexOf('"status": 429')) ??
          -1 >= 0
        ) {
          logError(
            `Failed to fetch Content Entry due to Contentful Rate Limit: "status": 429 for slug: ${queryOpts.slug}`,
            error,
          )
          throw error
        } else {
          logError(
            `Failed to fetch Content Entry for slug: ${queryOpts.slug}`,
            error,
          )
          return null
        }
      }

      if (!composePage) {
        logError(`Compose page is undefined for slug ${queryOpts.slug}`)
        return null
      }

      if (!composePage.metadata.tags.find((tag) => tag.sys.id === appTagId)) {
        logError(`Entry ${composePage.sys.id} missing tag "${appTagId}"`)
        return null
      }

      const templateType = getEntryTypeId(composePage.fields.content)

      const templateMap = {
        ...defaultStaticPropsTemplateMap,
        ...customTemplateMap,
      }

      if (!Object.keys(templateMap).includes(templateType)) {
        logError(`Invalid template type "${templateType}"`)
        logInfo(`composePage "${composePage}"`)
        logInfo(`composePage.fields.content "${composePage.fields.content}"`)
        logInfo(
          `composePage.fields.content.sys "${composePage.fields.content.sys}"`,
        )

        return null
      }

      const handleStaticProps =
        templateMap[templateType as keyof typeof templateMap]

      const composePageWithFilteredSubNavFields =
        filterSubNavLinkFields(composePage)

      return await handleStaticProps(
        composePageWithFilteredSubNavFields as any,
        queryOpts,
      )
    },
  )

/**
 * Necessary for ISG/SSG using dynamic routes, defines the paths to be built in build time. Any path not defined in `paths` is built on demand.
 */
export const getStaticPathsContentPage =
  ({
    slugBlacklist = [],
  }: {
    slugBlacklist?: string[]
  }): GetStaticPaths<{ slug: string[] }> =>
  async ({ locales }) => {
    const {
      nextConfig,
      contentfulConfig: { appTagId },
    } = getAppConfig()

    // Change to entries that include blog internal tag id
    const slugs = nextConfig.buildStatics
      ? await fetchSlugs(undefined, {
          isPreview: nextConfig.buildPreviewContent,
          tagId: appTagId,
        })
      : []

    const paths = getOptionalCatchAllRoutePaths(slugs, {
      slugBlacklist,
      // The Blog app has no localized content but we still want to support geo targeting, here we add an empty string to the locales array to avoid generating localized paths for over 1300 blog entries
      locales: appTagId === 'productsBlog' ? [''] : locales,
    })

    return {
      paths,
      fallback: appTagId === 'productsBlog' ? true : 'blocking',
    }
  }
